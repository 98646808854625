//react
import { CacheProvider } from "@emotion/react";
import { useEffect, useState } from "react";
//next.js
import { useRouter } from "next/router";
//helmet
import { Helmet, HelmetProvider } from "react-helmet-async";
//posthog
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
//swr
import { SWRConfig } from "swr";
//mui
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//other css
import "@vendor/jvectormap.css";
import "@vendor/perfect-scrollbar.css";
import "./style.css";
//sqlitecloud theme
import { ThemeProvider } from "@context/ThemeContext";
import useTheme from "@custom-hooks/useTheme";
import createEmotionCache from "@lib/createEmotionCache";
import createTheme from "@theme";
const clientSideEmotionCache = createEmotionCache();
//sqlitecloud libs
import analyzeError from "@lib/analyzeError";
//sqlitecloud context
import { StateProvider } from "@context/StateContext";
//sqlitecloud componenets
import ErrorBoundary from "@generic-components/ErrorBoundary";
//sqlitecloud hook
import { useGetUser } from "@custom-hooks/useApi";
import { useSetError } from "@custom-hooks/useSetError";
//check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  //init posthog
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

function App({ Component, emotionCache = clientSideEmotionCache, pageProps }) {
  //get router
  const router = useRouter();
  //get theme
  const { theme } = useTheme();
  //get layout component
  const getLayout = Component.getLayout ?? ((page) => page);
  //handle error
  const [error, setError] = useState(null);
  useSetError(error);
  //get user
  const { user } = useGetUser();
  //posthog identify user using email address
  useEffect(() => {
    if (user && user.email) {
      posthog.identify(user.email);
    }
  }, [user]);
  //posthog track page views
  useEffect(() => {
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  //render
  return (
    <PostHogProvider client={posthog}>
      <CacheProvider value={emotionCache}>
        <HelmetProvider>
          <Helmet titleTemplate="%s" defaultTitle="SQLite Cloud Dashboard" />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ErrorBoundary>
                <SWRConfig
                  value={{
                    onError: (error, key) => {
                      const result = analyzeError(error);
                      if (result.reportError) {
                        setError(error);
                      }
                    },
                    onErrorRetry: (
                      error,
                      key,
                      config,
                      revalidate,
                      { retryCount }
                    ) => {
                      const result = analyzeError(error);
                      // Only retry up to 10 times.
                      if (!result.retry || retryCount >= 10) return;
                      // Retry after 5 seconds.
                      setTimeout(() => revalidate({ retryCount }), 3000);
                    },
                  }}
                >
                  {getLayout(<Component {...pageProps} />)}
                </SWRConfig>
              </ErrorBoundary>
            </MuiThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </CacheProvider>
    </PostHogProvider>
  );
}

const withThemeProvider = (Component) => {
  const AppWithThemeProvider = (props) => {
    return (
      <ThemeProvider>
        <StateProvider>
          <Component {...props} />
        </StateProvider>
      </ThemeProvider>
    );
  };
  AppWithThemeProvider.displayName = "AppWithThemeProvider";
  return AppWithThemeProvider;
};

export default withThemeProvider(App);
